import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "store/store";
// import { RESET_STATE_ACTION_TYPE } from "store/action/resetState";
// import { endpoints } from "static";
// import { Logout } from "store/slices/authSlice";
import { RESET_STATE_ACTION_TYPE } from "store/action/resetState";
import { ssoLogoutPath } from "../../utils";

// const { LOGOUT } = endpoints;
const sso_logout_url = ssoLogoutPath();

// export const BaseQuery = fetchBaseQuery({
//   baseUrl: `${process.env.REACT_APP_SERVER_URL}`,
//   prepareHeaders: (headers, { getState }) => {
//     const { authReducer } = getState() as RootState;
//     headers.set("authorization", authReducer.token.access_token);
//     headers.set("organization", authReducer.organization_id);
//     return headers;
//   },
// });
//@ts-ignore
export const BaseQuery = async (args, api, extraOptions) => {
  // console.log({ api })
  const result = await fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const { authReducer } = getState() as RootState;
      headers.set("authorization", authReducer.token.access_token);
      headers.set("organization", authReducer.organization_id);
      return headers;
    },
  })(args, api, extraOptions);
  // console.log('status -------------> ', result);

  if (result?.error?.status === 401) {
    // console.log('i m here in interceptor of GM with 401   -> ', process.env.REACT_APP_SSO_ENABLE);
    if (process.env.REACT_APP_SSO_ENABLE === "true") {
      localStorage.clear();
      // console.log('i m here in if sso logout url', sso_logout_url);
      //localStorage.clear();
      //removeCookies();
      //api?.dispatch({ type: RESET_STATE_ACTION_TYPE });
      //window.location.href = sso_logout_url;
      //eslint-disable-next-line
      // setTimeout(function () {
        window.location.href = sso_logout_url;
      // }, 100);
      //return false;
    } else {
      console.log('i m here in else books logout url', { api });
      localStorage.clear();
      api?.dispatch({ type: RESET_STATE_ACTION_TYPE });
      // api
      //   ?.dispatch(Logout({ url: endpoints.LOGOUT }))
      //   .unwrap()
      //   .then(
      //     //@ts-ignore
      //     (res) => {
      //       if (res) {
      //         localStorage.clear();
      //         api?.dispatch({ type: RESET_STATE_ACTION_TYPE });
      //       }
      //     }
      // );
    }
  }

  return result;
};


