/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQuery } from "./BaseQuery";
import { endpoints } from "static";

const { APPROVALS } = endpoints;

export const ApprovalQuery = createApi({
  reducerPath: "Approvallist",
  keepUnusedDataFor: 3600,
  baseQuery: BaseQuery,
  endpoints: (builder) => ({
    getListing: builder.query<any, any>({
      query: (paginate) => {
        const { page = 1, pageSize = 10, filter, status, module = "trips" } = paginate;

        return {
          url: `${APPROVALS}?filter=${filter}&page=${page}&sort_by=${"created_at"}&order_by=${"desc"}&view=${pageSize}&search=${""}&status=${status}&module=${module}`,
          method: "get",
        };
      },
    }),
    getAdminListing: builder.query<any, any>({
      query: (paginate) => {
        const { page = 1, pageSize = 10, filter, status, module = "trips" } = paginate;

        return {
          url: `/admin${APPROVALS}?filter=${filter}&page=${page}&sort_by=${"created_at"}&order_by=${"desc"}&view=${pageSize}&search=${""}&status=${status}&module=${module}`,
          method: "get",
        };
      },
    }),

    // getEditTripById: builder.query<any, any>({
    //   query: (id: number) => {
    //     return { url: `${TRIP}/${id}/edit`, method: "get" };
    //   },
    // }),
    // getEditAdminTripById: builder.query<any, any>({
    //   query: (id: number) => {
    //     return { url: `/admin${TRIP}/${id}/edit`, method: "get" };
    //   },
    // }),
    // getViewTripById: builder.query<any, any>({
    //   query: (id: number) => {
    //     return { url: `${TRIP}/${id}`, method: "get" };
    //   },
    // }),
  }),
});

export const {
  useGetListingQuery,
  // useGetEditTripByIdQuery,
  // useGetEditAdminTripByIdQuery,
  // useGetViewTripByIdQuery,
  useGetAdminListingQuery,
} = ApprovalQuery;
