/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQuery } from "./BaseQuery";
import { endpoints } from "static";

const { TRIP } = endpoints;

export const tripQuery = createApi({
  reducerPath: "triplist",
  keepUnusedDataFor: 0,
  baseQuery: BaseQuery,
  endpoints: (builder) => ({
    getListing: builder.query<any, any>({
      keepUnusedDataFor: 0,

      query: (paginate) => {
        const { page = 1, pageSize = 10, filter, status } = paginate;

        return {
          url: `${TRIP}?filter=${filter}&page=${page}&sort_by=${"created_at"}&order_by=${"desc"}&view=${pageSize}&search=${""}&status=${status}`,
          method: "get",
        };
      },
    }),
    getAdminListing: builder.query<any, any>({
      query: (paginate) => {
        const { page = 1, pageSize = 10, filter, status } = paginate;

        return {
          url: `/admin${TRIP}?filter=${filter}&page=${page}&sort_by=${"created_at"}&order_by=${"desc"}&view=${pageSize}&search=${""}&status=${status}`,
          method: "get",
        };
      },
    }),
    getFormData: builder.query<any, any>({
      query: () => ({ url: `${TRIP}/create`, method: "get" }),
    }),
    getAdminFormData: builder.query<any, any>({
      query: () => ({ url: `/admin${TRIP}/create`, method: "get" }),
    }),

    getCities: builder.query<any, any>({
      query: (id: number) => {
        const path = id ? `${TRIP}/cities?country_id=${id}` : `${TRIP}/cities`;
        return { url: path, method: "get" };
      },
    }),
    getEditTripById: builder.query<any, any>({
      query: (id: number) => {
        return { url: `${TRIP}/${id}/edit`, method: "get" };
      },
    }),
    getEditAdminTripById: builder.query<any, any>({
      query: (id: number) => {
        return { url: `/admin${TRIP}/${id}/edit`, method: "get" };
      },
    }),
    getViewTripById: builder.query<any, any>({
      query: (id: number) => {
        return { url: `${TRIP}/${id}`, method: "get" };
      },
    }),
  }),
});

export const {
  useGetListingQuery,
  useGetFormDataQuery,
  useGetAdminFormDataQuery,
  useGetCitiesQuery,
  useGetEditTripByIdQuery,
  useGetEditAdminTripByIdQuery,
  useGetViewTripByIdQuery,
  useGetAdminListingQuery,
} = tripQuery;
