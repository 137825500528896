/** @format */
import { MouseEventHandler } from "react";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { Icons } from ".";
import { Labels } from "static";
import { FiSend } from "react-icons/fi";

const { YES, NO } = Labels;
const { AiOutlineEdit, RiDeleteBinLine, BsEye } = Icons;

type Iprops = {
  data: any;
  title?: string;
  organization_id?: number;
  handleClick?: MouseEventHandler | ((id: number) => void);
  handleConfirm?: MouseEventHandler | ((id: number) => void);
  handleViewClick?: (data: any) => void;
  view?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  deletePermission?: boolean;
  hideEdit?: boolean;
  showApproveButton?: boolean;
  handleApprove?: (data: any) => void;
};

export const RenderAction = ({
  data,
  title,
  organization_id,
  handleClick,
  handleConfirm,
  handleViewClick = () => null,
  view = false,
  disableEdit = false,
  disableDelete = false,
  deletePermission = false,
  hideEdit = false,
  showApproveButton = false,
  handleApprove,
}: Iprops) => {
  return (
    <>
      <Space>
        {view && (
          <Tooltip title="View">
            <Button
              shape="circle"
              icon={<BsEye />}
              onClick={() => handleViewClick(data.id)}
              disabled={!organization_id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Tooltip>
        )}
        {!hideEdit && (
          <Tooltip title="Edit">
            <Button
              shape="circle"
              icon={<AiOutlineEdit />}
              onClick={(e) => {
                e?.stopPropagation();
                handleClick?.(data.id);
              }}
              disabled={disableEdit}
            />
          </Tooltip>
        )}

        <Popconfirm
          key="confirm"
          okText={deletePermission ? YES : "Ok"}
          cancelText={NO}
          placement="left"
          title={title}
          //@ts-ignore
          onConfirm={(e) => {
            e?.stopPropagation();
            deletePermission && handleConfirm!(data.id);
          }}
          showCancel={deletePermission}
          disabled={disableDelete}
          onCancel={(e) => e?.stopPropagation()}
        >
          <Button
            onClick={(e) => e?.stopPropagation()}
            key="deletebtn"
            shape="circle"
            disabled={disableDelete}
          >
            <RiDeleteBinLine size={15} />
          </Button>
        </Popconfirm>

        {showApproveButton && (
          <Tooltip title="Send for Approval">
            <Button
              shape="circle"
              icon={<FiSend />}
              onClick={(e) => {
                e?.stopPropagation();
                handleApprove?.(data);
              }}
            />
          </Tooltip>
        )}
      </Space>
    </>
  );
};
