import { Tabs } from "antd";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
const { TabPane } = Tabs;
interface TabProps {
  tabsInfo: { tab: string; icon?: any; component: () => void }[];
  tabPosition?: "top" | "left";
  iconPosition?: "left" | "right";
  modal?: boolean;
}

export const TabView = ({
  tabsInfo,
  tabPosition = "top",
  iconPosition = "left",
  modal,
}: TabProps) => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: "0" });
  const [tabKey, setTabKey] = useState("0");
  const key = searchParams.get("tab");

  const onChange = (key: string) => {
    !modal && setSearchParams({ tab: key });
    setTabKey(key);
  };

  return (
    <Tabs
      activeKey={modal ? tabKey : key!}
      onChange={onChange}
      style={{ padding: 24 }}
      size={"large"}
      tabPosition={tabPosition}
    >
      {tabsInfo.map((menu: any, index: any) => (
        <TabPane
          tab={
            iconPosition === "left" ? (
              <span>
                {" "}
                {menu.icon} {menu.tab}{" "}
              </span>
            ) : (
              <span>
                {" "}
                {menu.tab} {menu.icon}{" "}
              </span>
            )
          }
          key={`${index}`}
        >
          {menu.component()}
        </TabPane>
      ))}
    </Tabs>
  );
};
