export const ManupulationPayload = (data: any, selectedObj: any, title: string) => {
  const flight_Details =
    data?.trip_flights?.flight_details?.length &&
    data?.trip_flights?.flight_details?.map((data: any) => {
      if (title === "depart") {
        return {
          ...data,
          depart_acode_id: { label: selectedObj?.label, value: selectedObj?.id },
          depart_aname: selectedObj?.label,
          depart_city: selectedObj?.city,
          depart_country: selectedObj?.country_id,
        };
      }
      if (title === "arrive") {
        return {
          ...data,
          dest_acode_id: { label: selectedObj?.label, value: selectedObj?.id },
          dest_aname: selectedObj?.label,
          dest_city: selectedObj?.city,
          dest_country: selectedObj?.country_id,
        };
      }
      if (title === "airline") {
        return {
          ...data,
          world_airline_id: { label: selectedObj?.name, value: selectedObj?.id },
          world_airline_name: selectedObj?.name,
          world_airline_iata_code: selectedObj?.iata_code,
          world_airline_country_id: selectedObj?.country_id,
        };
      }
    });

  //   const trip_flights = {
  //     ...data?.trip_flights,
  //     flight_details: flight_Details,
  //   };
  return { flight_Details };
};
