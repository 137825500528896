/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQuery } from "./BaseQuery";
import { endpoints } from "static";

const { CONTACTS } = endpoints;

export const contactQuery = createApi({
    reducerPath: "contact",
    keepUnusedDataFor: 3600,
    baseQuery: BaseQuery,
    endpoints: (builder) => ({
        getListing: builder.query<any, any>({
            query: () => ({ url: CONTACTS, method: "get" }),
        }),
        getFormData: builder.query<any, any>({
            query: () => ({ url: `${CONTACTS}/create`, method: "get" }),
        }),
    }),
});

export const { useGetListingQuery, useGetFormDataQuery } = contactQuery;