/**@format */

export const generateDisplayNameOptions = (
    salut: string,
    fname: string,
    lname: string
) => {
    const names: string[] = [];
    if (salut && !fname && !lname) names.push(`${salut}`);
    if (salut && fname && lname)
        names.push(
            `${lname} ${fname}`,
            `${fname} ${lname}`,
            `${salut} ${fname} ${lname}`
        );
    else if (!salut && fname && lname)
        names.push(`${fname} ${lname}`, `${lname} ${fname}`);
    else if (!salut && !fname && lname) names.push(`${lname}`);
    else if (!salut && !lname && fname) names.push(`${fname}`);
    else if (salut && !fname && lname) names.push(`${salut} ${lname}`);
    else if (salut && !lname && fname) names.push(`${salut} ${fname}`);
    return names;
};
