export const endpoints = {
  /** ------------- Auth Routes --------------- */
  SIGNUP: "/register",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  REGISTER_NEW_USER: "/register/inviteduser",
  FORGET_PASSWORD: "/password/forget",
  RESET_PASSWORD: "/password/forget/reset",
  EMAIL_VERIFIED: "/register/confirm",
  CURRENT_USER_ROLE: "currentuser/role",
  INVITE_CONFIRMATION: "/invites/accept",
  /** ------------- Auth Routes --------------- */

  /**---------------BMS Organization Routes */
  BMS_MODULE_INTEGRATIONS: "module_permissions",

  /** ------------- Protected Routes --------------- */
  CREATE_ORGANIZATION: "/register/organization",
  GET_ORGANIZATIONS_LIST: "/organizations",
  EDIT_ORGANIZATION: "/organizations",
  ORGANIZATIONS: "/organizations",
  GENREAL_MODULES: "/general_modules",
  SET_ORGANIZATION: "/organizations/set_default",
  WAREHOUSE: "/warehouses",
  CURRENCY: "/currencies",
  TAXES: "/taxes",
  TAX_GROUP: "/taxgroup",
  APPROVALS: "/approvals",
  ANALYTICS: "/analytics",
  ROLE: "/roles",
  CREATE_ROLE: "/create-roles",
  USERS: "/all-users",
  USER: "/organizations/users",
  INVITE_USER: "/invites",
  MEAL_PREFERENCE: "/mealpreference",
  SEAT_PREFERENCE: "/seatpreference",
  FLIGHT_PREFERENCE: "/flightpreference",
  TIME_PREFERENCE: "/timepreference",
  CAR_TYPE_PREFERENCE: "/cartypespreference",
  TRIP: "/trips",
  APPROVAL_SUMMARY: "trips/approvalsummary",
  // APPROVERS: "/users?role=Approver&orderBy=asc",
  APPROVERS: "/approvers?page=1&sort_by=name&order_by=asc",
  FORWARD_APPROVERS: "/forward_approvers?page=1&sort_by=name&order_by=asc",
  TAGS: "/tags",
  EXPENSE: "/expenses",
  REMOVE_EXPENSE: "/removeExpense",
  BULK_EXPENSE: "/bulkexpenses",
  EXPENSE_ADD_TO_REPORT: "/addtoreport",
  ADVANCE_ADD_TO_REPORT: "/addadvancetoreport",
  DRAFT_REPORTS: "/reportsforexpense",
  PAYMENTS_MODES: "/paymentmodes",
  REPORTS: "/reports",
  ADVANCES: "/advances",
  MERCHANT_PREFERENCE: "/merchant",
  CATEGORY: "/category",
  CONTACTS: "/contacts",
  ACCOUNTS: "/accounts",
  ACCOUNT_GROUPS: "/accountGroups",
  PURCHASE_REQUEST: "/purchaserequest",
  USER_PROFILE: "/auth/user",
  /** ------------- Admin Routes --------------- */

  /** ------------- Owner Routes --------------- */
  GENERAL_PREFERENCE: "/preferences/general"
  /** ------------- Owner Routes --------------- */
};
