import moment from "moment"

export const getFormetedDate = (date: any) => {
    if (!date || date == "") return null
    return moment(date).format("YYYY-MM-DD")
}

export const getTime = (date: any) => {
    return moment(date).format("HH:mm:ss")
}
export const getViewFormatDate = (date: any) => {
    if (!date || date == "") return null
    return moment(date).format("DD MMM YYYY")

}
