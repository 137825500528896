/** @format */

export const routeNames = {
  /** public route names */
  SINGUP: "/register",
  LOGIN: "/login",
  CHECK_LOGIN: "/check",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY: "/account/confirm",
  SSO: "/exp_sso",
  INVITE_CONFIRM: "/invite/accept/:token",
  DASHBOARD: "/dashboard",

  /** settings route names */
  REGISTER_ORGANIZATION: "/register-organization",
  ORGANIZATION_CREATE: "/organization-create",
  ORGANIZATION_PROFILE: "/organization-profile",
  ORGANIZATION_LISTING: "/organizations",
  TAGS: "/tags",
  USER_SETTINGS: "/usersettings",
  CURRENCY: "/currencies",
  TAX: "/taxes",
  USERS: "/users",
  ROLES: "/users/2",
  MEAL_PREFENRECES: "/mealpreferences",
  SEAT_PREFENRECES: "/seatpreferences",
  FLIGHT_PREFENRECES: "/flightpreferences",
  TIME_PREFENRECES: "/timepreferences",
  CAR_TYPE_PREFENRECES: "/carpreferences",
  GENERAL_PREFERNCES: "/generalpreferences",
  APPROVALS: "/approvals",
  TRIPS: "/trips",
  NEW_TRIP: "/trip/create",
  EDIT_TRIP: "/trip-edit",
  VIEW_TRIP: "/trip-view",
  EXPENSES: "/expenses",
  NEW_EXPENSE: "/new-expense",
  EDIT_EXPENSE: "/edit-expense/:id",
  PAYMENTS_MODES: "/paymentmodes",
  MODULES: "/modules",
  REPORTS: "reports",
  MODULES_TRIP: "/modules/trips",
  MODULES_EXPENSE: "/modules/expense",
  MODULES_MERCHANT: "/modules/merchant",
  MODULES_PAID_THROUGH: "/modules/paidthrough",
  MODULES_PERMISSION: "/modules/moduleprefrences",
  MODULES_CATEGORY: "/modules/category",
  ANALYTICS: "/analytics",
  ADVANCES: "/advances",
  CONTACTS: "/contacts",
  CONTACTS_CREATE: "/contacts/create",
  CONTACTS_EDIT: "/contacts/edit",
  ACCOUNTS: "/accounts",
  ROLE_CREATE: "/create-roles",
  ROLE_EDIT: "/create-roles/edit",
  PURCHASE_REQUEST: "/purchaseRequest",
  CREATE_PURCHASE_REQUEST: "/create-purchaseRequest",
  ITEMS: "/items",

  /** Admin Routnames */
  ADMIN_APPROVALS: "/admin/approvals",
  ADMIN_TRIPS: "/admin/trips",
  ADMIN_NEW_TRIP: "/admin/trip/create",
  ADMIN_EDIT_TRIP: "/admin/trip-edit",
  ADMIN_VIEW_TRIP: "/admin/trip-view",
  ADMIN_ADVANCES: "/admin/advances",
  ADMIN_SETTINGS: "/adminsettings",
  ADMIN_EXPENSES: "/admin/expenses",
  ADMIN_NEW_EXPENSE: "/admin/new-expense",
  ADMIN_EDIT_EXPENSE: "/admin/edit-expense/:id",
  TRIP_PREFERENCES: "/admin/trippreferences",
  ADVANCE_PREFERENCES: "/admin/advancepreferences",
  APPROVAL_PREFERENCES: "/admin/approvalpreferences",
};
