/** @format */

import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQuery } from "./BaseQuery";
import { endpoints } from "static";

const { ACCOUNT_GROUPS } = endpoints;

export const accountgroupsQuery = createApi({
    reducerPath: "accountGroups",
    keepUnusedDataFor: 3600,
    baseQuery: BaseQuery,
    endpoints: (builder) => ({
        getListing: builder.query<any, any>({
            query: () => ({ url: ACCOUNT_GROUPS, method: "get" }),
        }),
    }),
})

export const { useGetListingQuery } = accountgroupsQuery;
