/** @format */
import { ReactNode, useEffect } from "react";
import { Navigate, RouteObject, useNavigate, useRoutes } from "react-router-dom";
import * as AuthRoutes from "app/Auth";
import * as AppRoutes from "app/containers";
import { routeNames } from "static";
import { NotFound } from "app/shared";
import { PrivateRoute } from "./Private";
import { useStore } from "app/Hooks";

export const RouterConfig = ({ auth }: { auth: boolean }) => {
  const navigate = useNavigate();
  const { organization_id } = useStore();

  useEffect(() => {
    auth && !organization_id && navigate(routeNames.REGISTER_ORGANIZATION);
    //eslint-disable-next-line
  }, [auth, organization_id]);

  const AuthRoute = ({ component }: { component: ReactNode }) => (
    <PrivateRoute auth={auth}>{component}</PrivateRoute>
  );
  const CheckAuth = ({ children }: { children: ReactNode }) =>
    auth && !window?.location.pathname.includes("/invite/accept") ? (
      <Navigate to="/dashboard" />
    ) : (
      <>{children}</>
    );

  const publicRoutes: RouteObject[] = [
    {
      path: routeNames.CHECK_LOGIN,
      element: (
        <CheckAuth>
          <AuthRoutes.CheckLogin />
        </CheckAuth>
      ),
    },
    {
      path: routeNames.LOGIN,
      element: (
        <CheckAuth>
          <AuthRoutes.Login />
        </CheckAuth>
      ),
    },
    {
      path: routeNames.SINGUP,
      element: (
        <CheckAuth>
          <AuthRoutes.SignUp />
        </CheckAuth>
      ),
    },
    {
      path: routeNames.LOGIN,
      element: (
        <CheckAuth>
          <AuthRoutes.Login />
        </CheckAuth>
      ),
    },
    {
      path: routeNames.FORGET_PASSWORD,
      element: (
        <CheckAuth>
          <AuthRoutes.ForgetPassword />
        </CheckAuth>
      ),
    },
    {
      path: routeNames.RESET_PASSWORD,
      element: (
        <CheckAuth>
          <AuthRoutes.ResetPassword />
        </CheckAuth>
      ),
    },
    {
      path: routeNames.VERIFY,
      element: (
        <CheckAuth>
          <AuthRoutes.Verification />
        </CheckAuth>
      ),
    },
    {
      path: routeNames.INVITE_CONFIRM,
      element: <AuthRoutes.InviteConfirmation />,
    },
    {
      path: routeNames.SSO,
      element: <AuthRoutes.Sso />,
    },
    {
      path: "/",
      element: (
        <CheckAuth>
          <AuthRoutes.LandingPage />
        </CheckAuth>
      ),
    },
  ];

  const routes = useRoutes([
    ...publicRoutes,
    {
      path: routeNames.DASHBOARD,
      element: <AuthRoute component={<AppRoutes.Dashboard />} />,
    },
    {
      path: routeNames.REGISTER_ORGANIZATION,
      element: (
        <PrivateRoute auth={auth} layout={false}>
          <AppRoutes.Register />
        </PrivateRoute>
      ),
    },
    {
      path: routeNames.ORGANIZATION_LISTING,
      element: <AuthRoute component={<AppRoutes.Organizations />} />,
    },
    {
      path: routeNames.ORGANIZATION_CREATE,
      element: <AuthRoute component={<AppRoutes.CreateOrganization />} />,
    },
    {
      path: routeNames.ORGANIZATION_PROFILE,
      element: <AuthRoute component={<AppRoutes.EditOrganization />} />,
    },
    {
      path: routeNames.TRIPS,
      element: <AuthRoute component={<AppRoutes.Trips />} />,
    },
    {
      path: routeNames.NEW_TRIP,
      element: <AuthRoute component={<AppRoutes.NewTrip />} />,
    },
    {
      path: `${routeNames.EDIT_TRIP}/:id`,
      element: <AuthRoute component={<AppRoutes.EditTrip />} />,
    },
    {
      path: `${routeNames.VIEW_TRIP}/:id`,
      element: <AuthRoute component={<AppRoutes.ViewTrip />} />,
    },
    {
      path: routeNames.ADMIN_TRIPS,
      element: <AuthRoute component={<AppRoutes.AdminTrips />} />,
    },
    {
      path: routeNames.ADMIN_NEW_TRIP,
      element: <AuthRoute component={<AppRoutes.AdminNewTrip />} />,
    },
    {
      path: `${routeNames.ADMIN_EDIT_TRIP}/:id`,
      element: <AuthRoute component={<AppRoutes.AdminEditTrip />} />,
    },
    {
      path: `${routeNames.ADMIN_VIEW_TRIP}/:id`,
      element: <AuthRoute component={<AppRoutes.AdminViewTrip />} />,
    },
    {
      path: routeNames.MODULES,
      element: <AuthRoute component={<AppRoutes.MODULES />} />,
    },
    {
      path: routeNames.MODULES_TRIP,
      element: <AuthRoute component={<AppRoutes.ModuleTrips />} />,
    },
    {
      path: routeNames.MODULES_EXPENSE,
      element: <AuthRoute component={<AppRoutes.ModuleExpense />} />,
    },
    {
      path: routeNames.MODULES_MERCHANT,
      element: <AuthRoute component={<AppRoutes.ModuleMerchant />} />,
    },
    {
      path: routeNames.MODULES_PAID_THROUGH,
      element: <AuthRoute component={<AppRoutes.PaymentModes />} />,
    },
    {
      path: routeNames.MODULES_CATEGORY,
      element: <AuthRoute component={<AppRoutes.ModuleCategories />} />,
    },
    {
      path: routeNames.MODULES_PERMISSION,
      element: <AuthRoute component={<AppRoutes.ModulePermisson />} />,
    },
    { path: routeNames.CURRENCY, element: <AuthRoute component={<AppRoutes.Currency />} /> },
    { path: routeNames.APPROVALS, element: <AuthRoute component={<AppRoutes.Approvals />} /> },
    {
      path: routeNames.ADMIN_APPROVALS,
      element: <AuthRoute component={<AppRoutes.AdminApprovals />} />,
    },
    { path: routeNames.ANALYTICS, element: <AuthRoute component={<AppRoutes.Analytics />} /> },
    { path: routeNames.TAX, element: <AuthRoute component={<AppRoutes.Tax />} /> },
    {
      path: routeNames.MEAL_PREFENRECES,
      element: <AuthRoute component={<AppRoutes.MealPreferences />} />,
    },
    {
      path: routeNames.GENERAL_PREFERNCES,
      element: <AuthRoute component={<AppRoutes.GeneralPreferences />} />,
    },

    {
      path: routeNames.CONTACTS,
      element: <AuthRoute component={<AppRoutes.Contacts />} />,
    },
    {
      path: routeNames.CONTACTS_CREATE,
      element: <AuthRoute component={<AppRoutes.NewContacts />} />,
    },
    {
      path: `${routeNames.CONTACTS_EDIT}/:id`,
      element: <AuthRoute component={<AppRoutes.EditContacts />} />,
    },

    {
      path: routeNames.SEAT_PREFENRECES,
      element: <AuthRoute component={<AppRoutes.SeatPreferences />} />,
    },
    {
      path: routeNames.FLIGHT_PREFENRECES,
      element: <AuthRoute component={<AppRoutes.FlightPreferences />} />,
    },
    {
      path: routeNames.TIME_PREFENRECES,
      element: <AuthRoute component={<AppRoutes.TimePreferences />} />,
    },
    {
      path: routeNames.CAR_TYPE_PREFENRECES,
      element: <AuthRoute component={<AppRoutes.CarTypePreferences />} />,
    },
    { path: routeNames.EXPENSES, element: <AuthRoute component={<AppRoutes.Expenses />} /> },
    {
      path: routeNames.NEW_EXPENSE,
      element: <AuthRoute component={<AppRoutes.CreateExpense />} />,
    },
    {
      path: routeNames.EDIT_EXPENSE,
      element: <AuthRoute component={<AppRoutes.EditExpenses />} />,
    },
    {
      path: routeNames.ADMIN_EXPENSES,
      element: <AuthRoute component={<AppRoutes.Admin_Expenses />} />,
    },
    {
      path: routeNames.ADMIN_NEW_EXPENSE,
      element: <AuthRoute component={<AppRoutes.Admin_CreateExpense />} />,
    },
    {
      path: routeNames.ADMIN_EDIT_EXPENSE,
      element: <AuthRoute component={<AppRoutes.Admin_EditExpenses />} />,
    },
    {
      path: routeNames.TAGS,
      element: <AuthRoute component={<AppRoutes.TAGS />} />,
    },
    {
      path: routeNames.ITEMS,
      element: <AuthRoute component={<AppRoutes.Items />} />,
    },
    {
      path: routeNames.PURCHASE_REQUEST,
      element: <AuthRoute component={<AppRoutes.PurchaseRequest />} />,
    },
    {
      path: `${routeNames.PURCHASE_REQUEST}/:id`,
      element: <AuthRoute component={<AppRoutes.EditPurchaseRequest />} />,
    },
    {
      path: routeNames.CREATE_PURCHASE_REQUEST,
      element: <AuthRoute component={<AppRoutes.CreatePurchaseRequest />} />,
    },
    {
      path: routeNames.PAYMENTS_MODES,
      element: <AuthRoute component={<AppRoutes.PaymentModes />} />,
    },
    {
      path: routeNames.REPORTS,
      element: <AuthRoute component={<AppRoutes.REPORTS />} />,
    },
    {
      path: `${routeNames.REPORTS}/:id`,
      element: <AuthRoute component={<AppRoutes.REPORT_DETAIL />} />,
    },
    {
      path: routeNames.ADVANCES,
      element: <AuthRoute component={<AppRoutes.ADVANCES />} />,
    },
    {
      path: routeNames.ADMIN_ADVANCES,
      element: <AuthRoute component={<AppRoutes.AdminAdvances />} />,
    },
    {
      path: routeNames.ADMIN_SETTINGS,
      element: <AuthRoute component={<AppRoutes.ADMIN_SETTINGS />} />,
    },
    {
      path: routeNames.USER_SETTINGS,
      element: <AuthRoute component={<AppRoutes.USER_SETTINGS />} />,
    },
    {
      path: routeNames.ACCOUNTS,
      element: <AuthRoute component={<AppRoutes.Accounts />} />,
    },
    {
      path: `${routeNames.USERS}/:id`,
      element: <AuthRoute component={<AppRoutes.UserRoles />} />,
      // children: [
      //   {
      //     index: true,
      //     element: <AuthRoute component={<Users />} />,
      //   },
      //   {
      //     path: ROLES,
      //     element: <AuthRoute component={<Roles />} />,
      //   },
      // ],
    },
    {
      path: routeNames.ROLE_CREATE,
      element: <AuthRoute component={<AppRoutes.CreateRoles />} />,
    },
    {
      path: `${routeNames.ROLE_EDIT}/:id`,
      element: <AuthRoute component={<AppRoutes.EditRoles />} />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return routes;
};
